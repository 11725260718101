.salesHeadRFP{
    background-color: rgb(255, 148, 166);
}
.salesHeadRFP:hover{
    background-color: rgb(233, 106, 127);

}

.salesHeadPPT{
    background-color: rgb(61, 197, 61);
}

.salesHeadPPT:hover{
    background-color: rgb(52, 164, 52);
}

.lifeTool{
    background-color: blueviolet;
}

.lifeTool:hover{
    background-color: rgb(114, 32, 190);
}

.adminPanel{
    background-color: blueviolet;
}

.adminPanel:hover{
    background-color: rgb(114, 32, 190);
}

.feedback{
    background-color: rgb(125, 197, 17);
    color:rgb(5, 140, 140)

}

.feedback:hover{
    background-color: rgb(13, 133, 15);
}

.project{
    background-color: rgb(77, 195, 216);
    color:darkblue

}

.project:hover{
    background-color: rgb(53, 140, 155);
}

.hover:hover{
background-color: rgb(201, 202, 196);
}

.hoverMarketing:hover{
    background-color: rgb(113, 238, 238);
}

.hoverSales:hover {
     background-color: rgb(84, 252, 84);
}

.hoverDesign:hover {
    background-color: rgb(32, 32, 207);
}

.hoverOperations:hover{
    background-color: yellow;
}

.hoverfinance:hover{
    background-color: rgb(252, 128, 128);
}

.spanStyle{
    color: blue;
    font-weight: 700;
}

.spanStyle:hover{
    color: black;
}
