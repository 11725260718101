.cardUser{
    /* width: 50%; */
}
.cardUser1{
    /* width: 40%;   */
}

.form-group1{
    margin-bottom: 0.5rem;
}

.form-group1 .row{
    flex-wrap: nowrap;
}

.required-label::after {
    content: '*';
    color: red;
    margin-left: 2px;
  }
  