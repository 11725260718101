 .demo-wrapper1{
    width: 130%;
    border:  1px solid rgb(196, 196, 196) ;
    border-top: none;
    border-radius: 8px;
    /* border-left: none; */
    overflow:hidden!important;
    height: 145px;
}

.demo-editor1{
    border: none;
    width: 129%;
    background: none;
    margin-left: 2.5px;
 } 

 .card-body1{
    background-color: #e9e9e9;
    border-radius: 8px;
    height: auto;
 }
 
 .card-body3{
    /* margin-left: 1.9rem; */
    background-color: #e9e9e9;
    border-radius: 8px;
    height: auto;
 }
