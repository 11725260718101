@media print {
  /* Override styles for the textarea during printing */
  .size1 {
    overflow: hidden  !important ; /* Make it unscrollable */
    /* display: block; */
    height: 200px;
    /* width: auto; */
    /* Set display property to "block" */
  }
 
}
