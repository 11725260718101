
.rdw-editor-main {
  height: 300px !important;
  
  border: 1px solid black;
}

.priority{
  border: 1px solid black;
  text-align: center;
  width: 80px;
  border: none;
  border-radius: 5px;
}

.card-body{
  font-size: 15px;
}

.form-control-fs{
  height: 30px;
  font-size: 0.9rem;
  padding: 0px 0.27rem;
}

.form-group-h{
  margin-bottom: 0.39rem;
}

/* Add this CSS to your existing CSS file or component's styles */
.required-label::after {
  content: '*';
  color: red;
  margin-left: 2px;
}

.icon1{
  font-size: 1.2rem;
}

.link-no-underline:hover {
  text-decoration: none;
}

